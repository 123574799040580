import React from 'react';
import "./index.css";

const Button = (props) =>
    <a href={`#${props.to}`}><li className="inline-block p-4 hover:bg-blue-300">{props.children}</li></a>;

export const App = () =>
    <div>
        <div className="w-full bg-blue-200">
            <div className="mx-auto md:container bg-blue-500 text-white">

                <header className="inline-block text-xl m-3 mr-10">Sprinkathon</header>
                <nav className="inline-block">
                    <ul className="flex flex-row">
                        <Button to="about">Get started</Button>
                        <Button to="testimonies">Testimonies</Button>
                        <Button to="certification">Certification</Button>
                        <Button>About</Button>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="mx-auto md:container">
            <div id="about" className="mb-10">
                <h2>Introducing Sprinkathon</h2>
                <p className="text-xl mt-4">Introducing Sprinkathon - the high-impact fusion of a design sprint and a hackathon! In just a short burst of focused creativity, our innovative framework empowers teams to swiftly tackle specific challenges, ideate inventive solutions, and rapidly prototype game-changing innovations. With its unique blend of structured problem-solving and intense collaboration, Sprinkathon drives results in a fraction of the time, fostering a dynamic environment where diverse talents converge to create actionable solutions. Join us and revolutionize problem-solving in a fast-paced, outcome-driven atmosphere!</p>
            </div>
            <div id="testimonies" className="mb-10">
                <h2>Testimonies</h2>
                <p className="mt-4">
                    <b>Sarah, UX Designer:</b> "The Sprinkathon was an incredible experience! In just a couple of days, we collaborated intensely, applying design thinking principles to craft a solution that surpassed our expectations. The focused sprint format allowed us to ideate, prototype, and test our concepts rapidly. It was amazing to witness how diverse perspectives came together, resulting in an innovative solution that truly addressed the problem at hand."
                </p>
                <p className="mt-4">
                    <b>Mark, Software Developer:</b> "Participating in the Sprinkathon was like a whirlwind of creativity! The condensed timeframe pushed us to think on our feet and develop a working prototype in record time. The combination of a design sprint's structured approach and a hackathon's energy was the perfect recipe for innovation. I was amazed by the speed at which our team could iterate and refine our idea, leading to a solution we were proud to present."
                </p>
                <p className="mt-4">
                    <b>Emily, Marketing Specialist:</b> "The Sprinkathon was a revelation! I loved how it brought together experts from different fields to collaborate intensely on a focused problem. As a marketer, being part of a team where my insights were valued alongside designers and developers was inspiring. We created a prototype that not only addressed the challenge but also had a clear strategy for market adoption. It was an eye-opening experience!"
                </p>
                <p className="mt-4">
                    <b>Alex, Entrepreneur:</b> "As an entrepreneur, time is everything, and the Sprinkathon was a game-changer. It condensed the innovation process into a concise, results-driven format. The event allowed me to see how quickly a team could ideate, build, and validate a concept. The collaborative atmosphere and rapid prototyping taught me valuable lessons in agile development that I'll certainly apply in my own ventures."
                </p>
                <p className="mt-4">
                    <b>Liam, Product Manager:</b> "Participating in the Sprinkathon was an invigorating challenge! The structured sprint gave our team a clear direction, ensuring we stayed focused on the end goal. Witnessing the synergy between diverse skill sets was impressive. We didn’t just create a prototype; we validated it through rapid user testing. The experience was transformative, teaching us how to swiftly move from ideation to a tangible solution while keeping user needs at the forefront."
                </p>
            </div>

            <h2 id="certification">Certification Path for a Certified Sprinkathon Facilitator</h2>

            <ol className="list-decimal">
                <li className="mb-3">
                    <h3>Foundational Knowledge:</h3>
                    <ul>
                        <li><strong>Design Thinking Fundamentals:</strong> Gain a comprehensive understanding of design thinking methodologies, including empathy mapping, problem framing, ideation, prototyping, and testing.</li>
                        <li><strong>Agile and Project Management:</strong> Familiarize yourself with agile methodologies, project management principles, and the ability to manage short-term, high-impact projects effectively.</li>
                    </ul>
                </li>

                <li className="mb-3">
                    <h3>Training and Workshops:</h3>
                    <ul>
                        <li><strong>Participate in Sprinkathons:</strong> Engage as a participant or observer in several Sprinkathons to understand the dynamics, challenges, and best practices of the framework.</li>
                        <li><strong>Attend Facilitation Workshops:</strong> Enroll in workshops or training programs that focus on facilitation techniques, effective team collaboration, and group dynamics.</li>
                    </ul>
                </li>

                <li className="mb-3">
                    <h3>Certification Programs:</h3>
                    <ul>
                        <li><strong>Certified Design Thinking Practitioner:</strong> Obtain a certification in design thinking from reputable institutions or training providers. This certification should cover the core aspects of design thinking methodologies.</li>
                        <li><strong>Agile or Scrum Certification:</strong> Consider getting certified in agile methodologies or Scrum to understand how to manage rapid and iterative development processes effectively.</li>
                    </ul>
                </li>

                <li className="mb-3">
                    <h3>Specialized Sprinkathon Facilitation Training:</h3>
                    <ul>
                        <li>Look for specialized training programs or certifications that specifically focus on Sprinkathon facilitation. These programs should cover the unique aspects of combining design sprints and hackathons, emphasizing quick ideation, prototyping, and problem-solving in a short time frame.</li>
                    </ul>
                </li>

                <li className="mb-3">
                    <h3>Practical Experience and Mentorship:</h3>
                    <ul>
                        <li>Gain hands-on experience by facilitating smaller-scale events or projects that follow a similar format to Sprinkathons.</li>
                        <li>Seek mentorship or guidance from experienced Sprinkathon facilitators to learn practical tips, strategies, and best practices.</li>
                    </ul>
                </li>

                <li className="mb-3"> 
                    <h3>Assessment and Certification:</h3>
                    <ul>
                        <li>Undergo an assessment or certification process offered by an organization or institution that specializes in Sprinkathon facilitation. The certification process should evaluate your understanding of the Sprinkathon framework, your ability to manage rapid ideation processes, facilitate effective teamwork, and handle time-bound challenges.</li>
                    </ul>
                </li>

                <li className="mb-3">
                    <h3>Continued Learning and Improvement:</h3>
                    <ul>
                        <li>Stay updated on emerging trends, tools, and methodologies related to rapid prototyping, design thinking, and facilitation.</li>
                        <li>Continuously seek opportunities for professional development and refining your skills as a Sprinkathon facilitator.</li>
                    </ul>
                </li>
            </ol>
        </div>

        <footer></footer>
    </div>;